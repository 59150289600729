import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';

import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import { useSiteMetadata } from '../../hooks';

type Props = {
  title?: string,
  children: React.Node
};

const Page = ({ title, children }: Props) => {
  const pageRef = useRef();


  const { author, copyright } = useSiteMetadata();


  return (
    <div className={styles['page']}>
      <div className={styles['page__inner']}>
        {title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
      <Author author={author} />
      <Contacts contacts={author.contacts} />
      <Copyright copyright={copyright} />
    </div>
  );
};

export default Page;